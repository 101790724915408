/** @jsx jsx */
import { jsx } from 'theme-ui';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../components/layout';

const Post = ({ data: { blogPost, previous, next } }) => {
  return (
    <Layout>
      <MDXRenderer>{blogPost.body}</MDXRenderer>
    </Layout>
  );
};

export default Post;
